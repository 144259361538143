export default async function useLawyer() {
  const url = apiPaths.lawyerAuth()
  const lawyer = useState<Lawyer | null>('lawyer', () => null)

  const refresh = async () => {
    const { data } = await useData<Lawyer>(url)
    lawyer.value = data.value
  }

  if (!lawyer.value) {
    await refresh()
  }

  return { lawyer, refresh }
}